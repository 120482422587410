import { Paper, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import WebpAnimation from "./WebpAnimation";
import { DARK_THEME } from "../..";

const FaceTypeIndicator = ({ text, img }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseEnter}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: 104,
        alignItems: "center",
        justifyContent: "end",
        maxWidth: 200,
        marginTop: 3,
        backgroundColor: "#222222",
        borderRadius: "5px 5px 0px 0px",
        p: 1,
      }}
    >
      <Box sx={{ maxWidth: 40 }}>
        <WebpAnimation
          img={img}
          hovered={hovered}
          setIsHovered={setHovered}
          maxPlayTime={6000}
        />
      </Box>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          color: text === "Recipient Face" ? DARK_THEME.blue : DARK_THEME.orange,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default FaceTypeIndicator;
