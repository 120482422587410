import React, { useContext, useEffect, useRef, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import {
  Button,
  Card,
  Paper,
  Slider,
  SliderThumb,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Fab,
  ButtonGroup,
  IconButton,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { debounce, throttle } from "lodash";
import { Forward10, Replay10 } from "@mui/icons-material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import clsx from "clsx";
import { sliderThumbStyle } from "../../styles/sliderThumbStyle";

import { useDispatch } from "react-redux";
import {
  updateDstTimeArrByInd,
  updateDstYoutubeStringsByInd,
} from "../../redux/clipPageSlice";
import { formatDuration } from "../../util";

const ClipCard = ({ cropObj, ind, maxLength }) => {
  const dispatch = useDispatch();

  // Slider states & constants
  const minDistance = 10;
  const [vidInfo, setVidInfo] = useState(null);
  const [vidDuration, setVidDuration] = useState(300);
  const [isMuted, setIsMuted] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [sliderVals, setSliderVals] = useState([0, 1, 20]);
  const [playerLoaded, setPlayerLoaded] = useState(false);

  const [isExpanded, setIsExpanded] = useState(true);

  const playerRef = useRef<any>(null);

  // Quality selector

  const updateReduxTimeArr = (arr) => {
    dispatch(updateDstTimeArrByInd({ ind: ind, arr: arr }));
  };

  // Get video's title via oembed
  useEffect(() => {
    async function getInf() {
      const response = await fetch(
        `https://www.youtube.com/oembed?url=${cropObj.fullYoutube}`
      );
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Error fetching oEmbed data :(");
      }
    }
    getInf()
      .then((videoInfo) => {
        setVidInfo(videoInfo);
      })
      .catch((error) => console.error(error));
    setVidInfo(getInf());
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateReduxTimeArr(sliderVals);
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        if (
          playerRef.current.getPlayerState() === 1 &&
          Math.floor(currentTime) !== sliderVals[1]
        ) {
          setSliderVals([sliderVals[0], Number.parseInt(currentTime), sliderVals[2]]);
        }
        if (currentTime >= sliderVals[2] || currentTime < sliderVals[0]) {
          // playerRef.current.pauseVideo();
          playerRef.current.seekTo(sliderVals[0]); // reset player
        }
      }
    }, 300); // Check for out of time bounds every 100ms
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [sliderVals]);

  useEffect(() => {
    if (isMuted) {
      playerRef.current?.mute();
    } else {
      playerRef.current?.unMute();
    }
  }, [isMuted]);

  useEffect(() => {
    if (playerLoaded) {
      if (isPaused) {
        playerRef.current?.pauseVideo();
      } else {
        playerRef.current?.playVideo();
      }
    }
  }, [isPaused]);

  // Maintain accuracy of pause/unmute toggle btn pos after collapse
  useEffect(() => {
    if (isExpanded) {
      setIsPaused(false);
      setIsMuted(true);
    }
  }, [isExpanded]);

  const handleSliderChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) return;
    if (newValue[2] - newValue[0] < minDistance) {
      // If one thumb needs to "push" the other because of minDistance
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], vidDuration - minDistance);
        let midPoint = newValue[1];
        if (newValue[0] === newValue[1]) {
          midPoint++;
        }
        setSliderVals([clamped, midPoint, clamped + minDistance]);
      } else if (activeThumb === 1) {
        playerRef.current.seekTo(newValue[1]);
      } else if (activeThumb === 2) {
        const clamped = Math.max(newValue[2], minDistance);
        setSliderVals([clamped - minDistance, newValue[1], clamped]);
      }
    } else {
      // If one thumb needs to "pull" the other because of maxLength,
      // or if the middle thumb is being dragged
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[2], newValue[0] + maxLength);
        let midPoint = newValue[1];
        if (newValue[0] === newValue[1]) {
          midPoint += 10;
        }
        setSliderVals([newValue[0], midPoint, clamped]);
      } else if (activeThumb === 1) {
        playerRef.current.seekTo(newValue[1]);
      } else if (activeThumb === 2) {
        const clamped = Math.max(newValue[0], newValue[2] - maxLength);
        let midPoint = newValue[1];
        if (newValue[2] <= newValue[1]) {
          midPoint -= 10;
        }
        setSliderVals([clamped, midPoint, newValue[2] - 1]);
      }
    }
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    playerRef.current = event.target;
    const player = event.target;
    setPlayerLoaded(true);
    // player.pauseVideo();
    player.mute();
    setVidDuration(player.getDuration());
  };

  const opts: YouTubeProps["opts"] = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
    },
  };

  const handlePlayPause = () => {
    setIsPaused(!isPaused);
  };

  const handleMuteUnmute = () => {
    setIsMuted(!isMuted);
  };

  const handleForwardTen = () => {
    const currTime = playerRef.current?.getCurrentTime();
    playerRef.current?.seekTo(currTime + 10);
  };

  const handleBackTen = () => {
    const currTime = playerRef.current?.getCurrentTime();
    playerRef.current?.seekTo(currTime - 10);
  };

  function customThumbComponent(props) {
    const { children, className, ...other } = props;
    const extraClassName = other["data-index"] === 1 ? "middle-thumb" : "bounds-thumb";
    return (
      <SliderThumb {...other} className={clsx(className, extraClassName)}>
        {children}
      </SliderThumb>
    );
  }

  customThumbComponent.propTypes = {
    children: PropTypes.node,
  };

  const handleRemoveVideo = () => {
    dispatch(
      updateDstYoutubeStringsByInd({
        ind: ind,
        fullYoutube: "",
        youtubeId: "",
      })
    );
  };

  const handleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setPlayerLoaded(false);
    }
  };

  return (
    <Box className="flex flex-col items-center px-2 pb-2" sx={{ minWidth: "80vw" }}>
      <Card className="flex flex-col items-center w-full md:w-4/5  bg-gray-700 p-0">
        <Paper className="flex w-full justify-between p-1">
          <div onClick={handleExpandCollapse} className="flex p-1 cursor-pointer">
            <div className={isExpanded ? "" : "-rotate-90 -translate-x-1 -translate-y-1"}>
              <KeyboardArrowDownIcon className="ml-1 mr-3" sx={{ scale: "1.2" }} />
            </div>
            <Typography>{vidInfo?.title}</Typography>
          </div>
          <div onClick={handleRemoveVideo} className="cursor-pointer p-1 mr-1">
            <DeleteIcon htmlColor="Red" />
          </div>
        </Paper>

        {isExpanded ? (
          <div className="flex flex-col items-center w-full">
            <div className="mb-6 mt-4">
              {cropObj.youtubeId ? (
                <YouTube
                  videoId={cropObj.youtubeId}
                  opts={opts}
                  onReady={onPlayerReady}
                />
              ) : (
                "badUrl"
              )}
            </div>

            <div className="mt-7">
              <ButtonGroup size="large" sx={{ zIndex: 1100 }}>
                <Button onClick={handleBackTen}>
                  <Replay10 />
                </Button>
                <Button
                  variant={isPaused ? "contained" : "outlined"}
                  sx={{ zIndex: 1100 }}
                  onClick={handlePlayPause}
                >
                  {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
                </Button>
                <Button onClick={handleForwardTen}>
                  <Forward10 />
                </Button>
              </ButtonGroup>
              <div className="absoulte z-0">
                <Button
                  size="small"
                  variant={isMuted ? "contained" : "outlined"}
                  onClick={handleMuteUnmute}
                  sx={{
                    position: "relative",
                    top: "-73px",
                    left: "69px",
                    width: "5px",
                    borderRadius: 500,
                    zIndex: 100,
                  }}
                >
                  {isMuted ? (
                    <VolumeOffIcon sx={{ position: "relative", height: "20px" }} />
                  ) : (
                    <VolumeUpIcon sx={{ position: "relative", height: "20px" }} />
                  )}
                </Button>
              </div>
            </div>
            <Box sx={{ px: 10, mt: 4, mb: 10, width: "100%" }}>
              <Slider
                slots={{ thumb: customThumbComponent }}
                value={sliderVals}
                valueLabelFormat={formatDuration}
                onChange={handleSliderChange}
                max={vidDuration}
                valueLabelDisplay="auto"
                disableSwap
                sx={sliderThumbStyle}
              />
            </Box>
          </div>
        ) : null}
      </Card>
    </Box>
  );
};

export default ClipCard;
