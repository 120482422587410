import React, { useEffect, useState } from "react";
import { userInfoServerBaseUrl } from "../../env";
import ImageThumbnail from "./ImageThumbnail";
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ImageLibrary from "./ImageLibrary";
const PresetImageLibrary = ({
  srcOrDst,
  presetImages,
  setPresetImages,
  imgTabSelection,
  callback,
}) => {
  const [category, setCategory] = useState("Memes");
  const [showError, setShowError] = useState(null);
  const [loading, setLoading] = useState(true);
  const categories = [
    "Memes",
    "Musicians",
    "Actors",
    "Athletes",
    "Politicians",
    "CEOs",
    "Historical",
  ];

  const sendRequest = async () => {
    setLoading(true);
    interface CustomResponse extends Response {
      img: string[] | null;
    }

    const response = (await fetch(`${userInfoServerBaseUrl}/presets`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        category,
      }),
    })) as CustomResponse;

    setLoading(false);

    if (response.ok) {
      const res = await response.json();
      console.log("RESPONSE IS " + JSON.stringify(res));
      if (!res.error) {
        const formattedRes = res.map((url) => {
          const filename = url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("."));

          const personName = filename
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          return { url, name: personName };
        });
        const newPresetImages = { ...presetImages };
        newPresetImages[category] = formattedRes;
        setPresetImages(newPresetImages);
        return;
      } else {
        setShowError(res.error);
      }
    } else {
      const res = await response.text();
      setShowError("Couldn't connect to user info server " + res);
    }
  };

  useEffect(() => {
    if (imgTabSelection === "presets") {
      console.log(`Requesting presets/${category}`);
      sendRequest();
    }
  }, [category]);

  const handleChangeCategory = (cat: string) => {
    setCategory(cat);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          mx: 1,
        }}
      >
        {categories.map((cat) => (
          <Button
            key={cat}
            variant="contained"
            sx={{
              borderRadius: "20px",
              backgroundColor: cat !== category ? "#343434" : null,
              "&:hover": {
                backgroundColor: cat !== category ? "#444444" : null,
              },
            }}
            onClick={() => handleChangeCategory(cat)}
          >
            {cat}
          </Button>
        ))}
      </Box>
      <ImageLibrary
        images={presetImages && presetImages[category] ? presetImages[category] : null}
        srcOrDst={srcOrDst}
        callback={callback}
        showName={true}
        selectable={true}
        isResults={false}
        loading={loading}
      />
    </Box>
  );
};

export default PresetImageLibrary;
