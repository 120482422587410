import React, { useCallback, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { faCirclePlus, faFilm, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import xflogo from "../xflogo_200.png";
import { GoogleLogin } from "@react-oauth/google";
import xfgem from "../xfgem.png";
import { userInfoServerBaseUrl } from "../env";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  authSliceType,
  logout,
  updateBalance,
  updateBucketName,
  updateEmail,
  updateJwt,
  updatePicture,
  updateSubId,
} from "../redux/authSlice";
import { RootState } from "../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, useMediaQuery } from "@mui/material";
import { clearClipPageSlice } from "../redux/clipPageSlice";

export default function MenuAppBar({ signinInProgressRef }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location
  const isClipRoute = location.pathname === "/clip";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const auth = useSelector<RootState, authSliceType>((state) => state.auth);

  const [signinInProgress, setSigninInProgress] = useState(false);
  const [signedUpOrIn, setSignedUpOrIn] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean | string>(false);

  const isPortrait = useMediaQuery("(orientation: portrait)");

  let intervalId = null;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMyProfile = () => {
    navigate("/myProfile");
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClickSessions = () => {
    if (!signinInProgress) {
      navigate("/listSessions");
    } else {
      setInterval(() => {
        if (!signinInProgress) {
          navigate("/listSessions");
          clearInterval(intervalId);
        }
      }, 100);
    }
  };

  const handleClickImages = () => {
    if (!signinInProgress) {
      navigate("/listImages");
    } else {
      intervalId = setInterval(() => {
        if (!signinInProgress) {
          navigate("/listImages");
          clearInterval(intervalId);
        }
      }, 100);
    }
  };

  const handleClickHome = () => {
    if (!signinInProgress) {
      navigate("/");
    } else {
      intervalId = setInterval(() => {
        if (!signinInProgress) {
          navigate("/");
          clearInterval(intervalId);
        }
      }, 100);
    }
  };

  const handleNewSesh = () => {
    dispatch(clearClipPageSlice());
    if (!signinInProgress) {
      navigate("/clip");
    } else {
      intervalId = setInterval(() => {
        if (!signinInProgress) {
          navigate("/clip");
          clearInterval(intervalId);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (signinInProgressRef !== null) {
      signinInProgressRef.current = signinInProgress;
    }
  }, [signinInProgress, signinInProgressRef]);

  const onSuccess = useCallback(
    (credentialResponse: any) => {
      setSigninInProgress(true);
      console.log("SIGNED IN");
      console.log("credential is : " + credentialResponse.credential);
      const idToken = credentialResponse.credential; // Get the ID Token

      fetch(`${userInfoServerBaseUrl}/loginGoogle`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ idToken }),
      })
        .then((response) => response.json()) // Get JWT from response
        .then((data) => {
          if (!data.error) {
            console.log("login success:");
            console.log(data);
            dispatch(updateJwt(data.jwt));
            dispatch(updateEmail(data.email));
            dispatch(updatePicture(data.pictureUrl));
            dispatch(updateSubId(data.subId));
            dispatch(updateBucketName(data.bucketName));
            dispatch(updateBalance(data.balance));
            if (data.signedUp === true) {
              setSignedUpOrIn("up");
            } else {
              setSignedUpOrIn("in");
            }
          } else {
            setShowError(`${data.error}`);
            throw Error(`server returned error obj: ${data.error}`);
          }
        })
        .catch((error) => {
          console.error("Error calling google login:", error);
        });
      setSigninInProgress(false);
      setAnchorEl(null);
    },
    [dispatch]
  );

  return (
    <Box sx={{ flexGrow: 1, padding: "0px", margin: "0px", width: "100%" }}>
      <AppBar position="static">
        <Toolbar
          variant="dense"
          sx={{ display: "flex", justifyContent: "space-between", position: "relative" }}
        >
          <Box
            className="cursor-pointer"
            onClick={handleClickHome}
            sx={{
              display: "flex",
              flexDirection: "col",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {(isPortrait ? auth?.jwt : true) && (
              <img
                src={xflogo}
                alt="clip fake logo"
                style={{
                  maxHeight: "2.2rem",
                  imageRendering: "crisp-edges",
                  marginRight: "0.3rem",
                }}
              />
            )}
            {(isPortrait ? !auth?.jwt : true) && (
              <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                ClipFake
              </Typography>
            )}
          </Box>

          {!isClipRoute && (isPortrait ? auth?.jwt : true) && (
            <Box
              sx={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}
            >
              <Button variant="contained" onClick={handleNewSesh}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Box>
          )}

          {!auth?.jwt && (
            <GoogleLogin
              onSuccess={(credentialResponse: any) => onSuccess(credentialResponse)}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          )}
          {auth?.jwt && (
            <div className="flex items-center">
              {(isPortrait ? isClipRoute : true) && (
                <Tooltip arrow title="Balance">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      border: "1px solid rgb(171,102,255)",
                      backgroundColor: "rgb(55, 29, 87)",
                      "&:hover": {
                        backgroundColor: "rgb(72, 38, 114)",
                      },
                      borderRadius: "6px",
                      padding: "2px 6px 2px 6px",
                      marginRight: isPortrait ? "12px" : "20px",
                    }}
                  >
                    <img
                      src={xfgem}
                      alt="balance gems"
                      style={{ maxHeight: "20px", maxWidth: "20px" }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        color: "rgb(171,102,255)",
                        ml: 0.8,
                        mt: 0.3,
                      }}
                    >
                      {auth?.balance * 100}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
              <Tooltip arrow title={isPortrait ? "My Clips" : ""}>
                <Box
                  className="cursor-pointer flex items-center justify-center whitespace-nowrap"
                  onClick={handleClickSessions}
                  sx={{
                    marginRight: isPortrait ? "5px" : "20px",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFilm}
                    style={{
                      marginRight: "6px",
                      fontSize: isPortrait ? "22px" : "18px",
                    }}
                  />
                  {!isPortrait && (
                    <Typography
                      variant="body1"
                      sx={{ "&:hover": { textDecoration: "underline" }, mt: 0.4 }}
                    >
                      Clips
                    </Typography>
                  )}
                </Box>
              </Tooltip>
              <Tooltip arrow title={isPortrait ? "My Pics" : ""}>
                <Box
                  className="cursor-pointer flex items-center justify-center whitespace-nowrap"
                  onClick={handleClickImages}
                  sx={{
                    marginRight: isPortrait ? "5px" : "20px",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCamera}
                    style={{
                      marginRight: "6px",
                      fontSize: isPortrait ? "22px" : "18px",
                    }}
                  />
                  {!isPortrait && (
                    <Typography
                      variant="body1"
                      sx={{ "&:hover": { textDecoration: "underline" }, mt: 0.4 }}
                    >
                      Pics
                    </Typography>
                  )}
                </Box>
              </Tooltip>
              <div>
                <IconButton
                  size="large"
                  sx={{ transform: "scale(1.0)", padding: "0px" }}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <img
                    src={auth.picture}
                    className="rounded-full"
                    style={{ maxHeight: "33px", maxWidth: "33px" }}
                    alt="profpic"
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClickMyProfile}>My Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
