import React, { useEffect, useState } from "react";
import ImageLibrary from "./ImageLibrary";
import { userInfoServerBaseUrl } from "../../env";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { authSliceType } from "../../redux/authSlice";

const UserResultsLibrary = ({ srcOrDst, callback, isUploads }) => {
  const [images, setImages] = useState(null);
  const [showError, setShowError] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = useSelector<RootState, authSliceType>((state) => state.auth);

  const sendRequest = async () => {
    interface CustomResponse extends Response {
      img: string[] | null;
    }
    setLoading(true);

    const response = (await fetch(`${userInfoServerBaseUrl}/listImages`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        auth,
        isUploads,
      }),
    })) as CustomResponse;

    setLoading(false);

    if (response.ok) {
      const res = await response.json();
      console.log("RESPONSE IS " + JSON.stringify(res));
      if (!res.error) {
        setImages(res);
        return;
      } else {
        setShowError(res.error);
      }
    } else {
      const res = await response.text();
      setShowError("Couldn't connect to user info server " + res);
    }
  };

  useEffect(() => {
    if (auth?.jwt) {
      sendRequest();
    }
  }, [isUploads, auth]);

  return (
    <div>
      <ImageLibrary
        images={images}
        srcOrDst={srcOrDst}
        callback={callback}
        showName={false}
        selectable={srcOrDst}
        isResults={!isUploads}
        loading={loading}
      />
    </div>
  );
};

export default UserResultsLibrary;
