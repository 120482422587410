import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import { validateYouTubeUrl } from "../util";

const ResultInfo = ({ src, dst, dstIsVideo }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const playerRef = useRef<any>(null);
  const [playerLoaded, setPlayerLoaded] = useState(false);

  const opts: YouTubeProps["opts"] = {
    height: "270",
    width: "480",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 1,
    },
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    playerRef.current = event.target;
    const player = event.target;
    setPlayerLoaded(true);
    // player.mute();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "99vh",
        maxWidth: "99vw",
      }}
    >
      {dstIsVideo ? (
        <YouTube videoId={validateYouTubeUrl(dst)} opts={opts} onReady={onPlayerReady} />
      ) : (
        <Box
          component="img"
          src={dst}
          alt="recipient face"
          loading="lazy"
          sx={{
            cursor: "pointer",
            maxWidth: isSmallScreen ? "auto" : "40vw",
            height: isSmallScreen ? "33vh" : "auto",
            maxHeight: "63vh",
            borderRadius: 3,
          }}
        />
      )}
      <Typography variant="h2" sx={{ mx: 3 }}>
        +
      </Typography>
      <Box
        component="img"
        src={src}
        alt="donor face"
        loading="lazy"
        sx={{
          cursor: "pointer",
          maxWidth: isSmallScreen ? "auto" : "40vw",
          height: isSmallScreen ? "33vh" : "auto",
          maxHeight: "65vh",
          borderRadius: 3,
        }}
      />
    </Box>
  );
};

export default ResultInfo;
