import React, { useState } from "react";
import UserResultsLibrary from "./components/ClipPage/UserResultsLibrary";
import NavBar from "./components/NavBar";
import { Box, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ResultModal from "./components/ClipPage/ResultModal";

const ListImagesPage = (props) => {
  const [tglBtnAlignment, setTglBtnAlignment] = useState("results");

  const handleTglBtnAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment) {
      setTglBtnAlignment(newAlignment);
    }
  };

  return (
    <div>
      <div className="w-full mb-4">
        <NavBar signinInProgressRef={null} />
      </div>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          my: 6,
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "60vw",
            alignItems: "center",
            justifyContent: "center",
            "@media (orientation: portrait)": {
              minWidth: "95vw",
              maxWidth: "95vw",
            },
          }}
        >
          <ToggleButtonGroup
            fullWidth={true}
            className="w-max"
            value={tglBtnAlignment}
            exclusive
            onChange={handleTglBtnAlignment}
          >
            <ToggleButton value="results">Results</ToggleButton>
            <ToggleButton value="uploads">Uploads</ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ pt: 3 }}>
            <UserResultsLibrary
              srcOrDst={null}
              callback={null}
              isUploads={tglBtnAlignment === "uploads"}
            />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default ListImagesPage;
