import React, { useState } from "react";
import axios from "axios";
import { userInfoServerBaseUrl } from "../../env";
import { useDispatch, useSelector } from "react-redux";
import { clearVideos, updateDstImg, updateSrcImg } from "../../redux/clipPageSlice";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { RootState } from "../../redux/store";
import { authSliceType } from "../../redux/authSlice";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import { Oval } from "react-loader-spinner";

const ImageUpload = ({ srcOrDst, callback }) => {
  const dispatch = useDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const [showError, setShowError] = useState(null);
  const [loading, setLoading] = useState(false);
  const auth = useSelector<RootState, authSliceType>((state) => state.auth);

  const handleImageChange = (event) => {
    console.log("IMAGE SELECT");
    handleUpload(event.target.files[0]);
  };

  const handleUpload = async (selectedImage) => {
    if (!selectedImage) {
      console.error("No image selected");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedImage);

    let response = null;
    try {
      response = await axios.post(`${userInfoServerBaseUrl}/upload-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.stringify(auth)}`,
        },
      });
      console.log("Image uploaded:", response.data);
      setLoading(false);
      if (response.error) {
        throw Error(response.error);
      }
      if (srcOrDst === "src") {
        dispatch(updateSrcImg(response.data.publicUrl));
        if (callback) {
          callback();
        }
      } else if (srcOrDst === "dst") {
        dispatch(updateDstImg(response.data.publicUrl));
        dispatch(clearVideos());
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      console.error(`Upload error:`);
      console.error(error);
      setShowError(`${error?.response?.data?.error}`);
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    console.log("Button clicked");
    document.getElementById("image-upload-input").click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleImageChange({ target: { files: event.dataTransfer.files } });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minWidth: "320px",
        padding: "0.5rem",
        marginTop: "1rem",
      }}
    >
      {showError ? (
        <div className="flex flex-col items-center justify-center my-2">
          <Typography color="red">{showError}</Typography>
        </div>
      ) : null}
      <Box sx={{ width: "100%", height: "100%" }}>
        {loading ? (
          <div className="flex flex-col items-center justify-center mt-8 mb-8">
            <Oval
              visible={true}
              height="30"
              width="30"
              color="#6686af"
              secondaryColor="#6686af"
              ariaLabel="oval-loading"
            />
            <Typography variant="body1" sx={{ marginTop: "0.5rem" }}>
              Detecting face...
            </Typography>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: `2px dashed ${isDragging ? "#446688" : "#6686af"}`,
              backgroundColor: isDragging ? "#9e9e9e" : "transparent",
              padding: "20px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              id="image-upload-input"
              onChange={handleImageChange}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
                zIndex: 100,
              }}
            />
            <Typography
              variant="body1"
              sx={{ marginTop: "0.8rem", marginBottom: "0.3rem", color: "#6686af" }}
            >
              Drag image here...
            </Typography>
            <AddReactionIcon sx={{ fontSize: "40px", color: "#6686af" }} />
            <Typography variant="body1" sx={{ marginTop: "0.5rem", color: "#6686af" }}>
              or click to select
            </Typography>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ImageUpload;
