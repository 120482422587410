import { configureStore } from "@reduxjs/toolkit";
import clipPageReducer from "./clipPageSlice";
import authReducer, { authCookieMiddleware } from "./authSlice";

export const store = configureStore({
  reducer: {
    clipPage: clipPageReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(authCookieMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
