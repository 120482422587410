import { createSlice } from "@reduxjs/toolkit";

export type cropObjType = {
  fullYoutube: string;
  youtubeId: string;
  timeArr: number[];
};

export type LoadingImage = {
  timeLeft: number;
  dstImg: string;
  id?: string;
  error?: string;
  result?: string;
};

export type clipPageSliceType = {
  quality: string;
  dstCropArr: cropObjType[];
  dstImg: string;
  srcImg: string;
  resultImg: LoadingImage[];
};

const initialState: clipPageSliceType = {
  quality: "720p",

  dstCropArr: [
    {
      fullYoutube: "",
      youtubeId: "",
      timeArr: [0, 0, 20],
    },
  ],
  dstImg: "",
  srcImg: "",
  resultImg: [],
};

const clipPageSlice = createSlice({
  name: "clipPage",
  initialState,
  reducers: {
    setQuality(state, action) {
      state.quality = action.payload;
    },
    updateDstTimeArrByInd(state, action) {
      const ind = action.payload.ind;
      state.dstCropArr[ind].timeArr = action.payload.arr;
    },
    updateDstYoutubeStringsByInd(state, action) {
      const ind = action.payload.ind;
      state.dstCropArr[ind].fullYoutube = action.payload.fullYoutube;
      state.dstCropArr[ind].youtubeId = action.payload.youtubeId;
    },
    addDstCropArr(state, action) {
      state.dstCropArr.push(action.payload);
    },
    updateDstImg(state, action) {
      state.dstImg = action.payload;
    },
    updateSrcImg(state, action) {
      state.srcImg = action.payload;
    },
    updateResultImg(state, action) {
      state.resultImg = action.payload;
    },
    clearClipPageSlice(state) {
      // Don't delete resultImg array
      const newState = { ...initialState };
      newState.resultImg = state.resultImg;
      return newState;
    },
    clearVideos(state) {
      state.dstCropArr = [
        {
          fullYoutube: "",
          youtubeId: "",
          timeArr: [0, 0, 20],
        },
      ];
    },
  },
});

export const {
  setQuality,
  updateDstTimeArrByInd,
  updateDstYoutubeStringsByInd,
  addDstCropArr,
  updateDstImg,
  updateSrcImg,
  updateResultImg,
  clearClipPageSlice,
  clearVideos,
} = clipPageSlice.actions; // Export actions
export default clipPageSlice.reducer;
