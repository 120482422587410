import zIndex from "@mui/material/styles/zIndex";

export const sliderThumbStyle = {
  color: "primary",
  height: 3,
  padding: "4px 0",
  "& .MuiSlider-thumb": {
    height: 16,
    width: 16,
    padding: 0,
    margin: 0,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    zIndex: 100,
    "&.bounds-thumb": {
      //   top: "14px",
      //   width: "27px",
      //   height: "27px",
      //   background: "linear-gradient(Red 70%, DarkGreen)",
      //   clipPath: "polygon(0% 100%, 50% 0%, 100% 100%)",
      //   borderRadius: "30px",
      //   zIndex: 200,

      top: "21px",
      width: 0,
      height: 0,
      border: "20px solid transparent",
      background: "transparent",
      borderTop: 0,
      borderBottom: "40px solid DarkGreen",
    },
  },
  "& .MuiSlider-track": {
    height: 3.5,
    padding: 0,
  },
  "& .MuiSlider-rail": {
    height: 3,
    padding: 0,
  },
};
