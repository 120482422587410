import { Box, Typography } from "@mui/material";
import React from "react";
import { DARK_THEME } from "..";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleOpenTerms = () => {
    navigate("/terms");
  };

  const handleOpenPrivacy = () => {
    navigate("/terms");
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: DARK_THEME.grayNav,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        py: 1.5,
        px: 3,
      }}
    >
      <Box className="flex flex-col items-start md:flex-row">
        <Typography
          variant="body1"
          sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
          onClick={handleOpenTerms}
        >
          Terms
        </Typography>
        <Typography
          variant="body1"
          className="md:pl-6"
          sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
          onClick={handleOpenPrivacy}
        >
          Privacy
        </Typography>
      </Box>
      <Box className="flex flex-col items-end md:flex-row">
        <Typography
          variant="body1"
          className="md:pr-6"
          sx={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
        >
          ClipFakeLLC@gmail.com
        </Typography>
        <Typography variant="body1">©ClipFake LLC 2024</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
