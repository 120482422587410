import React, { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  Typography,
  InputAdornment,
  Paper,
} from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useDispatch } from "react-redux";
import { updateDstYoutubeStringsByInd } from "../../redux/clipPageSlice";
import ImageSelectMain from "./ImageSelectMain";
import { validateYouTubeUrl } from "../../util";

const UploadCard = ({ ind, callback, handleAlignmentChange }) => {
  const dispatch = useDispatch();
  // Link is the local state for displaying text in the box
  const [tglBtnAlignment, setTglBtnAlignment] = useState<string | null>("youtube");
  const [imgTabSelection, setImgTabSelection] = useState("presets");
  const [textInput, setTextInput] = useState("");

  const handleTglBtnAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment) {
      setTglBtnAlignment(newAlignment);
      if (handleAlignmentChange) handleAlignmentChange(newAlignment);
    }
  };

  const submitLink = (event: any) => {
    setTextInput(event.target.value);
    let vidId = validateYouTubeUrl(event.target.value);

    if (vidId) {
      dispatch(
        updateDstYoutubeStringsByInd({
          ind: ind,
          fullYoutube: event.target.value,
          youtubeId: vidId,
        })
      );
      if (callback) callback();
    } else {
      window.alert("Please enter a valid Youtube URL");
      setTextInput("");
    }
  };

  return (
    <div className="flex flex-col items-center mx-2">
      <Paper
        className="flex flex-col place-items-center"
        sx={{
          transition: "all 0.5s ease",
          minWidth: "320px",
          "@media (orientation: portrait)": {
            minWidth: "95vw",
          },
        }}
      >
        <ToggleButtonGroup
          fullWidth={true}
          className="w-max"
          value={tglBtnAlignment}
          exclusive
          onChange={handleTglBtnAlignment}
        >
          <ToggleButton value="youtube">YouTube</ToggleButton>
          <ToggleButton value="photo">Photo</ToggleButton>
        </ToggleButtonGroup>
        {tglBtnAlignment === "youtube" ? (
          <form className="mx-4 my-4 px-8 w-full" onSubmit={submitLink}>
            <TextField
              id="linkinp"
              label="YouTube Link"
              onChange={(event: any) => {
                submitLink(event);
              }}
              value={textInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTubeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        ) : tglBtnAlignment === "photo" ? (
          <ImageSelectMain
            srcOrDst={"dst"}
            callback={callback}
            imgTabSelection={imgTabSelection}
            setImgTabSelection={setImgTabSelection}
          />
        ) : null}
      </Paper>
    </div>
  );
};

export default UploadCard;
