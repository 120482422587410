import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

const LoadingText = ({ loadingObj }) => {
  const o = loadingObj;

  const [clientSideEta, setClientSideEta] = useState(o.remainingTime);

  useEffect(() => {
    setClientSideEta(o.remainingTime);

    const timer = setInterval(() => {
      setClientSideEta((prevEta) => {
        if (prevEta <= 1) return 1;
        return prevEta - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [o.remainingTime]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="flex-col">
      <Typography variant="h6">
        {o.completionPercentage === "100"
          ? "Uploading video..."
          : o.completionPercentage
          ? `Deepfaking...\n${o.completionPercentage}%`
          : o.preview
          ? `Loading model...`
          : o.vmfound2?.queued || o.vmfound2?.queued === 0
          ? `Video is number ${o.vmfound2?.queued} in queue...`
          : o.vmfound2?.found
          ? "Starting VM..."
          : o.emdl1
          ? "Finding VM..."
          : o.time || o.init
          ? "Clipping video from source..."
          : "Connecting to session..."}
      </Typography>
      <Typography variant="h6">
        {o.remainingTime && `${o.completionPercentage}` !== "100"
          ? `ETA: ${formatTime(clientSideEta)}`
          : ""}
      </Typography>
    </div>
  );
};

export default LoadingText;
