import React from "react";
import NavBar from "../components/NavBar";
import { Box, Typography } from "@mui/material";
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <div className="">
      <ScrollToTop />
      <div className="w-full mb-4">
        <NavBar signinInProgressRef={null} />
      </div>
      <Box sx={{ my: 3, mx: 3 }}>
        <Typography variant="h3">ClipFake Terms of Service and Privacy Policy</Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          By accessing or using ClipFake ("the Service"), you agree to be bound by these
          Terms of Service ("Terms"). If you disagree with any part of the terms, you may
          not access the Service.
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            2. Description of Service
          </Typography>
          ClipFake is a platform that allows users to clip and modify short content using
          AI technology. Users can deposit money to receive credits, which can be used to
          create modified images or videos.
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            3. User Accounts
          </Typography>
          <Typography variant="body2" component="div">
            3.1. You may be required to create an account to use certain features of the
            Service.
            <br />
            3.2. You are responsible for maintaining the confidentiality of your account
            information.
            <br />
            3.3. You agree to create only one account per individual.
            <br />
            3.4. New accounts may receive a small number of free credits upon creation.
          </Typography>
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            4. Content Creation and Fair Use
          </Typography>
          <Typography variant="body2" component="div">
            4.1. Users agree to only clip and modify content in a way that ensures
            sufficient creative license is taken to create new, transformative content.
            <br />
            4.2. Users are responsible for ensuring their use of source material complies
            with applicable copyright laws and fair use principles.
          </Typography>
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            5. Prohibited Content
          </Typography>
          Users agree not to create or upload content that is:
          <Typography variant="body2" component="div">
            5.1. Abusive, harassing, or intended to harm others
            <br />
            5.2. Illegal or promotes illegal activities
            <br />
            5.3. Infringing on the intellectual property rights of others
          </Typography>
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            6. Payment and Refunds
          </Typography>
          <Typography variant="body2" component="div">
            6.1. Users can deposit money to receive credits for use on the Service.
            <br />
            6.2. All deposits are final and non-refundable.
            <br />
            6.3. ClipFake reserves the right to modify credit costs and values at any
            time.
          </Typography>
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            7. Content Visibility
          </Typography>
          Any content created using the Service should be considered publicly visible and
          may be viewed by other users or visitors to the platform.
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            8. Modifications to Service
          </Typography>
          ClipFake reserves the right to modify or discontinue, temporarily or
          permanently, the Service with or without notice.
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            9. Limitation of Liability
          </Typography>
          ClipFake shall not be liable for any indirect, incidental, special,
          consequential or punitive damages resulting from your use of the Service.
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            10. Governing Law
          </Typography>
          These Terms shall be governed by and construed in accordance with the laws of
          [Your Jurisdiction], without regard to its conflict of law provisions.
        </Typography>

        <Typography variant="body1" paragraph>
          <Typography variant="h5" gutterBottom>
            11. Privacy Policy
          </Typography>
          <Typography variant="body2" component="div">
            11.1. Information Collection: We collect information you provide directly to
            us, such as when you create an account or use our services.
            <br />
            11.2. Use of Information: We use the information we collect to operate,
            maintain, and improve our services.
            <br />
            11.3. Information Sharing: We do not share personal information with third
            parties except as necessary to provide our services or comply with legal
            obligations.
            <br />
            11.4. Data Security: We implement reasonable security measures to protect your
            personal information.
            <br />
            11.5. Changes to Privacy Policy: We may update this privacy policy from time
            to time. We will notify you of any changes by posting the new policy on this
            page.
          </Typography>
        </Typography>

        <Typography variant="body1" paragraph>
          By using ClipFake, you agree to our collection and use of information in
          accordance with this policy.
        </Typography>

        <Typography variant="body2">Last updated: August 29, 2024</Typography>
        <Typography variant="body2">© ClipFake LLC 2024</Typography>
      </Box>
      <Footer />
    </div>
  );
};

export default Terms;
