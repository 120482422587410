import { Box } from "@mui/material";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ThumbPlayer = ({ vid, img }) => {
  const [playClicked, setPlayClicked] = useState(false);

  return (
    <Box position="relative">
      {playClicked ? (
        <ReactPlayer
          url={vid}
          controls={true}
          width="100%"
          height="100%"
          playing={true}
          style={{ outline: "none", border: "none" }}
        />
      ) : (
        <Box position="relative">
          <Box component="img" src={img} sx={{ width: "100%", height: "100%" }} />
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              //   width: "50px",
              //   height: "50px",
              //   borderRadius: "50%",
              //   backgroundColor: "rgb(1,1,1,0.5)",
              padding: 5,
            }}
            onClick={() => setPlayClicked(true)}
          >
            <FontAwesomeIcon
              icon={faPlay}
              style={{ color: "rgb(255,255,255,0.8)", fontSize: 70 }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ThumbPlayer;
