import {
  Backdrop,
  Box,
  Button,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDstImg, updateSrcImg } from "../../redux/clipPageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBackward,
  faCircleInfo,
  faCircleXmark,
  faClose,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import ResultInfo from "../ResultInfo";
import ImageOrInfoModal from "../ImageOrInfoModal";

const ImageModal = ({
  modalActive,
  setModalActive,
  srcOrDst,
  img,
  callback,
  selectable,
  isResults,
}) => {
  const dispatch = useDispatch();

  const { url, name, dst, src, time } = img;
  const date = new Date(time);

  const [isInfoClicked, setIsInfoClicked] = useState(false);

  const handleClose = () => {
    setModalActive(false);
    setIsInfoClicked(false);
  };

  const handleSelect = () => {
    if (srcOrDst === "src") {
      dispatch(updateSrcImg(url || img));
      if (callback) callback();
    } else {
      dispatch(updateDstImg(url || img));
      if (callback) callback();
    }
  };

  const handleClickInfo = () => {
    setIsInfoClicked(true);
  };

  const handleDownload = () => {
    return;
  };

  return (
    <ImageOrInfoModal
      modalActive={modalActive}
      handleClose={handleClose}
      component={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isInfoClicked ? (
            <ResultInfo src={src} dst={dst} dstIsVideo={false} />
          ) : (
            <Box
              component="img"
              src={url || img}
              alt="the"
              loading="lazy"
              sx={{
                cursor: "pointer",
                width: "100%",
                height: "auto",
                maxHeight: "72vh",
                borderRadius: 1,
              }}
            />
          )}
          {isInfoClicked && (
            <Paper
              sx={{
                mt: 2,
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Created:
              </Typography>
              <Typography variant="body1">
                {format(date, "EEE MMM dd yyyy hh:mm:ssaa")}
              </Typography>
            </Paper>
          )}
          {selectable && (
            <Button
              sx={{ width: "100%", mt: "16px" }}
              variant="contained"
              onClick={handleSelect}
              size="large"
            >
              Select
            </Button>
          )}
          {isResults && (
            <Box sx={{ display: "flex", pt: "16px", gap: 1 }}>
              <Button variant="contained" onClick={handleDownload}>
                <Typography fontSize={16}>Download</Typography>
                <FontAwesomeIcon
                  icon={faDownload}
                  fontSize={18}
                  style={{ marginLeft: "5px" }}
                />
              </Button>
              <Button
                sx={{ pt: "4px", height: "100%" }}
                variant="contained"
                onClick={() => setIsInfoClicked(!isInfoClicked)}
                color={"secondary"}
              >
                {isInfoClicked ? (
                  <FontAwesomeIcon icon={faArrowLeft} fontSize={25} />
                ) : (
                  <FontAwesomeIcon icon={faCircleInfo} fontSize={25} />
                )}
              </Button>
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default ImageModal;
