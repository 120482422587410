import React, { useState, useEffect, useRef } from "react";

function WebpAnimation({ img, hovered, setIsHovered, maxPlayTime }) {
  const videoRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Set to first frame on mount
    }
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;

    const resetVideo = () => {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
      setIsPlaying(false);
    };

    const playVideo = async () => {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Error playing video:", error);
        setIsPlaying(false);
      }
    };

    if (maxPlayTime) {
      timeoutRef.current = setTimeout(() => {
        resetVideo();
        setIsHovered(false);
      }, maxPlayTime);
    }

    if (hovered && !isPlaying) {
      resetVideo();
      playVideo();
    } else if (!hovered && isPlaying) {
      resetVideo();
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [hovered, maxPlayTime, isPlaying]);

  return (
    <video
      src={img}
      ref={videoRef}
      loop={false}
      muted
      playsInline
      style={{
        objectFit: "cover", // Ensure video fits the container
      }}
    />
  );
}

export default WebpAnimation;
